$('.hp-slider').slick({
  autoplay: true
  ,dots: false
  ,arrows: false
});

// wheels / tires search
if ($('.hp').length) {
  if ($(window).width() < 640) {
      $('.js-vehicle-select').insertAfter('.vehicle-select__bottom');
      $('.js-footer-social').insertAfter('.support-list');
  }

  var moveHTML = debounce(function () {
      if ($(window).width() < 640) {
          $('.js-vehicle-select').insertAfter('.vehicle-select__bottom');
          $('.js-footer-social').insertAfter('.support-list');
      }
      if ($(window).width() > 640) {
          $('.js-vehicle-select').insertAfter('.vehicle-select__header');
          $('.js-footer-social').insertAfter('.footer__about');
      }
  }, 500);

  window.addEventListener('resize', moveHTML);


  // search bar
$('#wheels-specs-select.wheels-by-size select').change(function(){
  $('#wheels-specs-select.wheels-by-size .button[disabled]').prop('disabled',false);

});
$('#wheels-specs-select.tires-by-size select').change(function(){
  $('#wheels-specs-select.tires-by-size .button[disabled]').prop('disabled',false);

});

$('button:not(.menu-icon)').click(function(){
  loadSpinner();
});
}

// New Wheels ///////////////
$(window).load(function(){
  var $showNumber = 4;
  if($(window).width() <= 667) {
    $showNumber = 2;
  } else {
    $showNumber = 4;
  }
    
  
  $('#new-slider').slick({
      autoplay: true,
      dots: false, 
      arrows: true,
      slidesToShow: $showNumber,
      swipeToSlide: true,
      nextArrow: '<button class="new-next new-arrow" aria-label="Next" type="button"><i class="fas fa-chevron-right"></i></button>',
      prevArrow: '<button class="new-prev new-arrow" aria-label="Prev" type="button"><i class="fas fa-chevron-left"></i></button>',
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
      ]
  });
  

});

// loading spinner
function loadSpinner() {
$.magnificPopup.open({
  items: {
    src: '<div class="white-popup processing">' +
      '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>' +
      '</div>'
  },
  type: 'inline',
  modal: true
});
}