$(function () {
  function getAjax(URL, funct) {
    var $tireFilters = $('#tire-filters');
    try {
      $.ajax({
        url: URL + '&funct=' + funct,
        success: function (data) {
          if (funct === 'width') {
            $('#aspect').html(data);
            $('#aspect').removeAttr('disabled');
          } else if (funct === 'aspect') {
            $('#diam').html(data);
            $('#diam').removeAttr('disabled');
          } else {
            $('#tireResults').html(data);
          }
        }
      });
    } catch (err) {
      window.location.reload();
    }
  }

  function buildURL(method) {
    var $tireFilters = $('#tire-filters');
    var width = $tireFilters.find('#width').val();
    var aspect = $tireFilters.find('#aspect').val();
    var diam = $tireFilters.find('#diam').val();
    var url = '/ajax/connector.cfc?method=getTireSizes';
    if (method = 'width') {
      url += '&width=' + width;
    }
    return url;

  }
  $('#tire-filters #width').change(function () {
    var width = $(this).val();
    if (width !== '') {
      $('#diam').attr('disabled', 'disabled');
      var url = buildURL(width);
      //var url = 'getTireSizes.cfm?width=' + width + '&method=getTireSizes';
      getAjax(url, 'width');
    }
  });

  $('#tire-filters #aspect').change(function () {
    var width = $('#tire-filters').find('#width').val();
    var aspect = $(this).val();

    if (aspect !== '') {
      var url = buildURL();
      var url = '/ajax/connector.cfc?aspect=' + aspect + '&width=' + width + '&method=getTireSizes';
      getAjax(url, 'aspect');
    }
  });

  $('#tire-filters #diam').change(function () {
    var diam = $(this).val();
    if (diam !== '') {

      $.magnificPopup.open({
        items: {
          src: '<div class="white text-center processing">' +
            '<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>' +
            '</div>',
        },
        closeBtnInside: false,
      });

      var $tireFilters = $('#tire-filters');
      var width = $tireFilters.find('#width').val();
      var aspect = $tireFilters.find('#aspect').val();
      var url = '/tires/?aspect=' + aspect + '&width=' + width + '&diam=' + diam;
      window.location.href = url;
    }
  });

});


if($('#tire-brands').length){
  // gray out missing brands 
  let availableBrands = [];
  
  $('.products-list > .column').each(function(){
    let temp = $(this).attr('data-letter');
    if(!availableBrands.includes(temp)) {
      availableBrands.push(temp);
    }
  });
  availableBrands.sort();

  // loop through to see if button available;
  $('.brand-letter-search #tire-filter-search ul li').each(function(){
    let temp = $(this).find('a')
    let tempValue = temp.attr('href');
    let isAvailable = false;
    for(let i = 0; i < availableBrands.length; i++) {
      if(tempValue == availableBrands[i]) {
        isAvailable = true;
      } else if (tempValue == '0-9' && !isNaN(availableBrands[i])) {
        isAvailable = true;
      }
    }
    if (isAvailable == false) {
      temp.addClass('disable-letter');
    }
  });

  // END : gray out missing brands

};

if($('#tires, #tire-brands').length){

  ////// slide filter 
  var viewport_width = document.documentElement.clientWidth;
  if(viewport_width <= 640) {
    // Declare a fragment:
    var fragment = document.getElementsByClassName('product-filters')[0];
    var fragment2 = document.getElementsByClassName('filter-active')[0];

    // Append fragment to desired element:
    document.getElementsByClassName('slide-over-filter')[0].appendChild(fragment);
    if(typeof fragment2 !== "undefined") {
      document.getElementsByClassName('slide-over-filter')[0].insertBefore(fragment2,fragment);
    }
  }

  // mobile
  var viewport_width = document.documentElement.clientWidth;
  if(viewport_width <= 640) {
    $(window).scroll(function(){
      let fromTop = $(document).scrollTop();
      var cta = document.getElementsByClassName('top')[0];
      var pageHeader = document.getElementsByClassName('content-header')[0];
      var pageHeaderVehicle = document.getElementsByClassName('mobile-vehicle-container')[0];
      var header = document.getElementsByClassName('main')[0];

      if(fromTop > 60) {
        document.getElementsByClassName('main')[0].after(cta);
        document.getElementsByClassName('top')[0].before(pageHeader);
        header.classList.add('cta-added');
        if(typeof pageHeaderVehicle !== "undefined") {
          document.getElementsByClassName('top')[0].after(pageHeaderVehicle);
        }
      } else {
        document.getElementsByClassName('products-list')[0].before(cta)
        document.getElementsByClassName('page-content')[0].before(pageHeader)
        header.classList.remove('cta-added');
      }
    });
  }
  

};